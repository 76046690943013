/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { filter, find, get, isEmpty, map } from 'lodash';

import {
  useCatalogInfo,
  useCatalogSearchApi,
  useCategoryUrl,
  useFormatMessage,
  useScrollToTop
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import log from 'app/common/utils/Log/Log';
import { useBrowseRequestConfig } from 'app/search-and-browse/shared/hooks';

import messages from '../../CategoryList.messages';
import { Environment } from 'app/common/services';
import { TopBrandContext } from 'app/common/contexts';
import { useBrandsInfo } from 'app/brands/components/Brands/hooks';

const logger = log.getLogger('search-and-browse.category.CategoryList');
const facetsToInclude = ['attr-BRAND', 'categoryIds'];
const featuredBrandsEnabled =
  Environment.get('FEATURED_BRANDS_ENABLED') === 'true';

function useCategoryList({
  category,
  location,
  rootsOnly = false,
  isBrandsPath
}) {
  useScrollToTop([location.search]);
  const { activeTopBrand } = useContext(TopBrandContext);
  category = useCategory(category, rootsOnly);
  const formatMessage = useFormatMessage();
  const { id, name, subCategoryDetails, url: baseUrl } = category;
  const url = useCategoryUrl(baseUrl);
  const {
    categoryInfo: { routeBaseContext }
  } = useCatalogInfo();
  const { brandsRootPath } = useBrandsInfo();
  const config = useBrowseRequestConfig(
    id,
    location.search,
    1,
    facetsToInclude
  );
  const { error, exception, loading, response } = useCatalogSearchApi(config);
  const facets = useMemo(
    () =>
      get(response, 'facets', [])
        .filter(facet => facetsToInclude.includes(facet.facet.name))
        .map(({ facet, values }) => {
          return {
            expandedByDefault: true,
            facet,
            values: values
              .map(value => {
                if (featuredBrandsEnabled) {
                  return value;
                }
                const matchingSubCategory = find(subCategoryDetails, [
                  'id',
                  value.value
                ]);

                if (isEmpty(matchingSubCategory)) {
                  return null;
                }

                return {
                  ...value,
                  value: `${value.value}||${matchingSubCategory.name}`
                };
              })
              .filter(value => value !== null)
          };
        }),
    [response, subCategoryDetails]
  );
  const nonEmptySubCategories = useMemo(() => {
    const categoryIdsActuallyUsed = get(
      find(facets, facet => facet.facet.name === 'categoryIds'),
      'values',
      []
    ).map(({ value }) => value.split('||')[0]);

    return filter(subCategoryDetails, ({ id }) =>
      categoryIdsActuallyUsed.includes(id)
    );
  }, [facets, subCategoryDetails]);
  const breadcrumbs = useMemo(() => {
    const updatedBreadcrumbs = map(category.breadcrumbs, b => ({
      ...b,
      uri: isBrandsPath
        ? activeTopBrand?.brandsPath + b.uri
        : routeBaseContext + b.uri
    }));

    // Add Brand or Part Search breadcrumbs
    if (isBrandsPath) {
      if (activeTopBrand) {
        updatedBreadcrumbs.unshift({
          label: activeTopBrand.brandName,
          uri: activeTopBrand.brandsPath
        });
      }
      updatedBreadcrumbs.unshift({
        label: formatMessage(messages.brandsRootName),
        uri: brandsRootPath,
        searchQuery: rootsOnly ? '' : `category=${category.url.slice(1)}`
      });
    } else {
      updatedBreadcrumbs.unshift({
        label: formatMessage(messages.rootsOnlyName),
        uri: rootsOnly ? undefined : routeBaseContext
      });
    }

    return updatedBreadcrumbs;
  }, [
    category.breadcrumbs,
    formatMessage,
    rootsOnly,
    routeBaseContext,
    isBrandsPath,
    activeTopBrand,
    brandsRootPath,
    category.url
  ]);

  if (isEmpty(nonEmptySubCategories)) {
    logger.debug(
      `No subcategories for category ${name}:${id} despite using the CATEGORY_LIST template`
    );
  }

  if (error) {
    logError({
      ...exception,
      when: `fetching Products for Category ${name}:${id}`
    });
  }

  return useMemo(
    () => ({
      breadcrumbs,
      category,
      facets,
      loading,
      response,
      routeBaseContext,
      subCategories: nonEmptySubCategories,
      url
    }),
    [
      breadcrumbs,
      category,
      facets,
      loading,
      nonEmptySubCategories,
      response,
      routeBaseContext,
      url
    ]
  );
}

function useCategory(category, rootsOnly = false) {
  const formatMessage = useFormatMessage();
  return useMemo(
    () => ({
      ...category,
      description: rootsOnly
        ? formatMessage(messages.rootsOnlyDescription)
        : category.description,
      name: rootsOnly ? formatMessage(messages.rootsOnlyName) : category.name
    }),
    [category, formatMessage, rootsOnly]
  );
}

export default useCategoryList;
