/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, isNil, map } from 'lodash';
import { Redirect, useHistory, withRouter } from 'react-router-dom';

import {
  useCatalogInfo,
  useCatalogSearchApi,
  useCategoryUrl,
  useFormatMessage,
  useGtmPageView,
  useProductListImpressions,
  useScrollToTop
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import {
  removeZeroQuantitySelectedFacets,
  ResultsBody,
  ResultsHelmet
} from 'app/search-and-browse/shared/components';
import { ResultsLayoutProvider } from 'app/search-and-browse/shared/helpers';
import { useBrowseRequestConfig } from 'app/search-and-browse/shared/hooks';

import { BrowseHeader, FeaturedProducts } from './components';
import messages from 'app/search-and-browse/category/components/CategoryList/CategoryList.messages';
import TopBrandContext from 'app/common/contexts/TopBrandContext';
import { useBrandsInfo } from 'app/brands/components/Brands/hooks';

/**
 * Render component for the default CategoryBrowse view. This includes fetching
 * the products to display.
 *
 * @visibleName Default Category Browse
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const DefaultCategoryBrowse = ({ category, location, isBrandsPath }) => {
  useScrollToTop([location.search]);
  const { id, name, promotionalProducts, url: baseUrl } = category;
  const url = useCategoryUrl(baseUrl);
  const config = useBrowseRequestConfig(id, location.search);
  const { error, exception, loading, response } = useCatalogSearchApi(config);
  const history = useHistory();
  const { activeTopBrand } = React.useContext(TopBrandContext);

  if (error) {
    logError({
      ...exception,
      when: `fetching Products for Category ${name}:${id}`
    });
  }

  const pageNumber = get(response, 'number', 0);
  const totalPages = get(response, 'totalPages', 0);
  const size = get(response, 'size', 15);
  const products = get(response, 'content', []);
  const featuredProducts = get(promotionalProducts, 'FEATURED', []);

  const formatMessage = useFormatMessage();
  const {
    categoryInfo: { routeBaseContext = '' }
  } = useCatalogInfo();
  const { brandsRootPath } = useBrandsInfo();
  const breadcrumbs = React.useMemo(() => {
    const updatedBreadcrumbs = map(category.breadcrumbs, b => ({
      ...b,
      uri:
        b.uri === undefined
          ? undefined
          : isBrandsPath
          ? activeTopBrand?.brandsPath + b.uri
          : routeBaseContext + b.uri
    }));

    if (isBrandsPath) {
      if (activeTopBrand) {
        updatedBreadcrumbs.unshift({
          label: activeTopBrand.brandName,
          uri: activeTopBrand.brandsPath
        });
      }
      updatedBreadcrumbs.unshift({
        label: formatMessage(messages.brandsRootName),
        uri: brandsRootPath,
        searchQuery:
          `category=${category.breadcrumbs[0].uri.slice(1)}` +
          `&subcategory=${category.url.slice(1)}`
      });
    } else {
      updatedBreadcrumbs.unshift({
        label: formatMessage(messages.rootsOnlyName),
        uri: routeBaseContext
      });
    }

    return updatedBreadcrumbs;
  }, [
    category.breadcrumbs,
    formatMessage,
    routeBaseContext,
    isBrandsPath,
    activeTopBrand,
    brandsRootPath,
    category.url
  ]);
  useCategoryBrowseGtmView(
    products,
    pageNumber,
    name,
    featuredProducts,
    size,
    loading,
    response
  );

  if (!loading && response !== undefined) {
    const { redirect: redirectData = {} } = response;

    if (!isEmpty(redirectData) && !isNil(redirectData.redirectUri)) {
      return <Redirect push={true} to={redirectData.redirectUri} />;
    }
    removeZeroQuantitySelectedFacets(
      get(response, 'facets', []),
      location,
      history,
      'attr-BRAND'
    );
  }
  return (
    <main className="container relative flex-grow min-h-screen p-4 sm:py-8 md:px-6 lg:px-8">
      <ResultsLayoutProvider breadcrumbs={breadcrumbs}>
        <ResultsHelmet
          {...category}
          follow={true}
          index={true}
          pageNumber={pageNumber}
          totalPages={totalPages}
          url={url}
        />
        <BrowseHeader searchResponse={response} title={name} />
        <ResultsBody
          browse
          loading={loading}
          searchResponse={response}
          name={name}
        />
        {!isEmpty(featuredProducts) && (
          <FeaturedProducts
            featuredProducts={featuredProducts}
            categoryName={name}
          />
        )}
      </ResultsLayoutProvider>
    </main>
  );
};

function useCategoryBrowseGtmView(
  products,
  pageNumber,
  name,
  featuredProducts,
  size,
  loading,
  response
) {
  const gtmProducts = React.useMemo(() => {
    const allProducts = products.map((product, i) => ({
      category: get(product, 'explicitCategoryNames[0]'),
      id: product.sku,
      name: product.description,
      position: i * (pageNumber + 1),
      price: get(product, 'priceInfo.price.amount', 0.0),
      quantity: 1,
      list: name
    }));

    if (isEmpty(featuredProducts)) {
      return allProducts;
    }

    return allProducts.concat(
      featuredProducts
        .map(({ promotionalProduct }) => promotionalProduct)
        .map((product, i) => ({
          category: name,
          id: product.sku,
          name: product.description,
          position: i + pageNumber * size,
          price: get(product, 'priceInfo.price.amount', 0.0),
          quantity: 1,
          list: `${name} Featured Parts`
        }))
    );
  }, [featuredProducts, name, products, pageNumber, size]);
  const impressions = useProductListImpressions(gtmProducts, name);

  useGtmPageView(name, impressions, loading || response === undefined);
}

export default withRouter(DefaultCategoryBrowse);
export { DefaultCategoryBrowse };
