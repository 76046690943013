/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import cx from 'classnames';

import { Button, Icon } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import { TypeAheadSuggestions } from './components';
import { didRedirect, redirect } from './searchActions';
import messages from './Search.messages';
import { TopBrandContext } from 'app/common/contexts';

/**
 * Render component for a field to use for searching the site's content.
 *
 * @visibleName Nav Site Search Field
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Search = ({
  className,
  query,
  setQuery,
  isMobileSearchOpen,
  setMobileSearchOpen,
  mobile,
  searchReducerProps,
  desktop
}) => {
  const [{ shouldRedirect, ...redirectProps }, dispatch] = searchReducerProps;
  const containerRef = useRef(null);
  const formatMessage = useFormatMessage();
  const { setActiveTopBrand } = React.useContext(TopBrandContext);

  useEffect(() => {
    if (shouldRedirect) {
      dispatch(didRedirect());
      // reset the input value
      setQuery('');
    }
  }, [dispatch, setQuery, shouldRedirect]);

  const mobileClass = mobile ? 'flex relative flex-col h-full lg:hidden' : '';
  const renderTypeAhead = !shouldRedirect && desktop;
  return (
    <form
      aria-label={formatMessage(messages.ariaLabel)}
      className={cx(mobileClass, {
        [className]: !!className
      })}
      role="search"
      id={'search-form' + (mobile ? '-mobile' : '')}
      ref={containerRef}
      onSubmit={e =>
        handleSubmit(e, query, dispatch, setMobileSearchOpen, setActiveTopBrand)
      }
    >
      {desktop && (
        <DesktopSearch
          handleSubmit={e => {
            handleSubmit(
              e,
              query,
              dispatch,
              setMobileSearchOpen,
              setActiveTopBrand
            );
          }}
          query={query}
          setQuery={setQuery}
        />
      )}
      {mobile && (
        <MobileSearch
          onClick={() => {
            setMobileSearchOpen(!isMobileSearchOpen);
          }}
        />
      )}
      {renderTypeAhead && <TypeAheadSuggestions {...{ containerRef, query }} />}

      {shouldRedirect && <Redirect {...redirectProps} />}
    </form>
  );
};

const MobileSearch = ({ onClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Button
        className="flex self-end items-center justify-end flex-1 px-4 text-gray-700 hover:text-gray-800 hover:bg-gray-100"
        type="button"
        title={formatMessage(messages.submitLabel)}
        onClick={onClick}
      >
        <Icon
          name="search"
          containerSize="medium"
          className={'inline-flex items-center justify-center'}
        />
      </Button>
    </>
  );
};

const MobileSearchInput = ({
  query,
  setQuery,
  isMobileSearchOpen,
  setMobileSearchOpen,
  searchReducerProps
}) => {
  const formatMessage = useFormatMessage();
  if (!isMobileSearchOpen) {
    return null;
  }
  return (
    <div className="flex -mt-px py-1 text-white lg:hidden bg-gray-200 px-1">
      <input
        className="w-full py-1 pr-6 pl-10 text-gray-900 placeholder-gray-500 border border-gray-400 bg-white rounded appearance-none border-l-2 border-r-2
      focus:outline-none focus:shadow-outline"
        name="q"
        autoComplete="off"
        placeholder={formatMessage(messages.placeholder)}
        value={query}
        autoFocus={true}
        onChange={e => setQuery(e.target.value)}
        form={'search-form-mobile'}
        onBlur={e => {
          const relatedTarget = e.relatedTarget?.id;
          // don't close search if the
          // user is clicking on the clear button or this input
          if (
            relatedTarget === 'mobile-search-clear' ||
            relatedTarget === 'mobile-search-input'
          ) {
            return;
          }
          setMobileSearchOpen(false);
        }}
        type="text"
        id={'mobile-search-input'}
      />
      {!!query && (
        <button
          className="flex items-center absolute right-2 py-2 text-gray-500 z-10 hover:text-gray-900 focus:outline-none lg:right-4"
          type="reset"
          title={formatMessage(messages.clearSearch)}
          id={'mobile-search-clear'}
          onClick={() => setQuery('')}
        >
          <Icon name="times-circle" containerSize="medium" />
        </button>
      )}
    </div>
  );
};

function DesktopSearch({ handleSubmit, query, setQuery }) {
  const formatMessage = useFormatMessage();
  return (
    <div
      className="hidden items-center relative lg:px-1 lg:flex"
      id={'desktop-search-container'}
    >
      <button
        className="absolute flex items-center pr-2 pl-3 py-2 text-gray-700 z-10 cursor-default focus:outline-none sm:pr-3"
        type="submit"
        title={formatMessage(messages.submitLabel)}
      >
        <Icon name="search" containerSize="medium" />
      </button>
      <input
        id="desktop-search"
        className="w-full py-1 pr-6 pl-10 placeholder-gray-700 text-black rounded
         appearance-none focus:outline-none focus:shadow-outline lg:w-104 lg:pr-8"
        name="q"
        autoComplete="off"
        placeholder={formatMessage(messages.placeholder)}
        value={query}
        onChange={e => setQuery(e.target.value)}
        type="text"
      />
      {!!query && (
        <button
          className="flex items-center absolute right-2 py-2 text-gray-700 z-10 hover:text-gray-500 focus:outline-none lg:right-4"
          type="reset"
          title={formatMessage(messages.clearSearch)}
          onClick={() => setQuery('')}
        >
          <Icon name="times-circle" containerSize="medium" />
        </button>
      )}
    </div>
  );
}

const handleSubmit = function (
  e,
  query,
  dispatch,
  setMobileSearchOpen,
  setActiveTopBrand
) {
  e.preventDefault();
  if (!isEmpty(query)) {
    setMobileSearchOpen(false);
    setActiveTopBrand(null);
    dispatch(redirect(query));
  }
};

Search.propTypes = {
  /** class names to add to the component */
  className: PropTypes.string,
  /** whether to render the mobile search */
  mobile: PropTypes.bool,
  /** whether to render the desktop search */
  desktop: PropTypes.bool
};

export default Search;
export { MobileSearchInput, Search };
