/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';

import { PriceContext } from 'app/common/contexts';
import {
  useCatalogInfo,
  usePriceContextHeader,
  usePriceInfoContextHeader
} from 'app/common/hooks';
import { NotFound } from 'app/layout/components';

import {
  CategoryList,
  DefaultCategoryBrowse,
  DefaultCategoryBrowseSkeleton
} from '../../components';
import { useTopBrandState } from 'app/common/hooks/asset';
import { Environment } from 'app/common/services';
import { Redirect } from 'react-router-dom';
import {
  useSingleBrandApi,
  useBrandsInfo
} from 'app/brands/components/Brands/hooks';
import { useFetchCategoryApi } from '../../hooks';

const featuredBrandsEnabled =
  Environment.get('FEATURED_BRANDS_ENABLED') === 'true';

/**
 * Helper component used to both fetch the category matching the current
 * location and determine which category UI component to use to render the
 * data.
 *
 * @visibleName Category Renderer
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CategoryRenderer = ({ location }) => {
  const {
    categoryInfo: { routeBaseContext }
  } = useCatalogInfo();
  const { brandsRootPath } = useBrandsInfo();

  let { pathname } = location;
  const { isBrandsPath, brandUrl, activeCategoryUrl } = parseBrandsUrlParts(
    pathname,
    brandsRootPath
  );
  const { hasLoadedPriceLists } = React.useContext(PriceContext);
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const { stripBrandFromPath } = useTopBrandState();

  pathname = stripBrandFromPath(pathname.replace('/_cp', ''));
  const rootsOnly =
    pathname === routeBaseContext || (isBrandsPath && isNil(activeCategoryUrl));

  const categoryUrl = React.useMemo(() => {
    if (!isEmpty(activeCategoryUrl)) {
      return activeCategoryUrl;
    }

    const url = rootsOnly
      ? routeBaseContext
      : pathname.replace(routeBaseContext, '');
    return stripBrandFromPath(url);
  }, [
    pathname,
    routeBaseContext,
    rootsOnly,
    stripBrandFromPath,
    activeCategoryUrl
  ]);

  const { error: singleBrandError } = useSingleBrandApi(isBrandsPath, brandUrl);
  const { category, error: categoryError } = useFetchCategoryApi(
    categoryUrl,
    rootsOnly,
    priceContextHeader,
    priceInfoContextHeader,
    hasLoadedPriceLists
  );

  if (singleBrandError) {
    return <Redirect to={'/' + brandsRootPath} />;
  }

  if (categoryError) {
    return <NotFound />;
  }

  if (category === null) {
    return <DefaultCategoryBrowseSkeleton />;
  }

  const { displayTemplate } = category;

  if (isCategoryListPage(rootsOnly, displayTemplate)) {
    return (
      <CategoryList
        isBrandsPath={isBrandsPath}
        category={category}
        rootsOnly={displayTemplate === 'ROOT_CATEGORY_LIST'}
      />
    );
  }

  return (
    <DefaultCategoryBrowse category={category} isBrandsPath={isBrandsPath} />
  );
};

function isCategoryListPage(rootsOnly, displayTemplate) {
  return (
    ((featuredBrandsEnabled || !rootsOnly) &&
      displayTemplate === 'CATEGORY_LIST') ||
    displayTemplate === 'ROOT_CATEGORY_LIST'
  );
}

function parseBrandsUrlParts(pathname, brandsRootPath) {
  const brandsPath = brandsRootPath.startsWith('/')
    ? brandsRootPath.slice(1)
    : brandsRootPath;

  const urlParts = pathname.split('/');
  while (urlParts.length > 0 && urlParts[0] !== brandsPath) {
    urlParts.shift();
  }

  const isBrandsPath = urlParts[0] === brandsPath;
  const brandUrl = urlParts[1] ? '/' + urlParts[1] : null;
  const activeCategoryUrl =
    isBrandsPath && urlParts[2] ? '/' + urlParts[2] : null;

  return { isBrandsPath, brandUrl, activeCategoryUrl };
}

CategoryRenderer.propTypes = {
  /** Represents the current location of the app */
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    status: PropTypes.object
  }).isRequired
};

export default CategoryRenderer;
export { CategoryRenderer };
